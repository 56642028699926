import stripShopifyGid from 'lib/utils/stripShopifyGid';

export default function toItem(
  product,
  variant = product?.variants?.edges[0]?.node,
  additionalFields = {}
) {
  const discount = variant?.compareAtPriceV2?.amount
    ? parseFloat(variant?.compareAtPriceV2?.amount) - parseFloat(variant?.priceV2?.amount)
    : 0;

  const {
    title,
    id,
    vendor,
    style,
    articleNumber,
    genericColorName,
    genericFit,
    gender,
    materialComposition: material,
    productType,
  } = product;

  const selectedOptions = variant?.selectedOptions.reduce(
    (acc, { name, value }) => ({ ...acc, [name.toLowerCase()]: value }),
    {}
  );

  return {
    id: stripShopifyGid(id),
    variant: variant?.sku,
    vendor,
    name: title,
    price: parseFloat(variant?.priceV2.amount),
    discount,
    article_number: articleNumber,
    style,
    fit: genericFit,
    category: productType,
    color_group: genericColorName,
    gender,
    material,
    ...selectedOptions,
    ...additionalFields,
  };
}
